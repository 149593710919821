// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBXUWx4g-wfDDLq9wO2SS-L5LDjDCtMJYc',
    authDomain: 'shortim.firebaseapp.com',
    databaseURL: 'https://shortim.firebaseio.com',
    projectId: 'shortim',
    storageBucket: 'shortim.appspot.com',
    messagingSenderId: '215277294904'
  }
};

// <script src="https://www.gstatic.com/firebasejs/5.7.2/firebase.js"></script>
// <script>
//   // Initialize Firebase
//   var config = {
//     apiKey: "AIzaSyBXUWx4g-wfDDLq9wO2SS-L5LDjDCtMJYc",
//     authDomain: "shortim.firebaseapp.com",
//     databaseURL: "https://shortim.firebaseio.com",
//     projectId: "shortim",
//     storageBucket: "shortim.appspot.com",
//     messagingSenderId: "215277294904"
//   };
//   firebase.initializeApp(config);
// </script>
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
