import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Account } from '../../app/models/account/account.interface';
import { ToastController } from '@ionic/angular';
import { AuthService } from '../../app/auth.service';
import { LoginResponse } from '../../app/models/login/login.response.interface';
import { DataService } from '../../app/data.service';
import { User } from 'firebase/app';
import { Subscription } from 'rxjs';
import { Profile } from '../../app/models/profile/profile.interface';


// problems with this function - double calls to get user, added observable ,
// need to add validation for the number , area , user etc.


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  account = {} as Account;
  // phoneNumber: string;
  profile = {} as Profile;
  @Output() registerStatus: EventEmitter<LoginResponse>;
  authUser: User;
  authUser$: Subscription;

  constructor(private auth: AuthService,
    private toastCntrl: ToastController,
    private data: DataService) {
    this.registerStatus = new EventEmitter<LoginResponse>();
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.authUser$.unsubscribe();
    console.log('destroy');
  }
  async register() {
    // tslint:disable-next-line:no-debugger
    // debugger;
    try {
      const result = await this.auth.createUserWithEmailAndPassword(this.account);
      this.registerStatus.emit(result);
      this.saveUserNumber();
      this.saveProfile();

    } catch (e) {
      this.registerStatus.emit(e);
    }
  }
  async saveUserNumber() {
    this.authUser$ = this.auth.getAuthUser().subscribe((user: User) => {
      this.authUser = user;
      console.log(this.authUser);
      console.log(this.authUser.uid);
      this.data.savePhoneNumber(this.authUser.uid, this.profile.phoneNumber);

    });

  }

  async saveProfile() {
    this.authUser$ = this.auth.getAuthUser().subscribe((user: User) => {
      this.authUser = user;
      const result =
        this.data.saveProfileByNumber(this.profile.phoneNumber, this.profile);
    });

  }
}
