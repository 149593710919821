import { Component, OnInit , EventEmitter, Output } from '@angular/core';
import { Account } from '../../app/models/account/account.interface';
import { LoginResponse } from '../../app/models/login/login.response.interface';
import { AuthService } from '../../app/auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  account = {} as Account;
  @Output() loginStatus: EventEmitter<LoginResponse>;
    constructor(private auth: AuthService) {
    this.loginStatus = new EventEmitter<LoginResponse>();
    }

  ngOnInit() {
  }


  async login() {
    const loginResponse = await this.auth.signInWithEmailAndPassword(this.account);
    this.loginStatus.emit(loginResponse);
  }
}

