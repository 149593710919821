import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import {LoginFormComponent} from './login-form/login-form.component';
import {RegisterFormComponent} from './register-form/register-form.component';
import {EditProfileFormComponent} from './edit-profile-form/edit-profile-form.component';
import { EditNanositeFormComponent } from './edit-nanosite-form/edit-nanosite-form.component';

import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// import { AppRoutingModule } from '../app/app-routing.module';
// import { RouterModule } from '@angular/router';
const routes: Routes = [];


@NgModule({
  declarations: [LoginFormComponent , RegisterFormComponent, EditProfileFormComponent, EditNanositeFormComponent],
  entryComponents: [],
  imports: [IonicModule , RouterModule.forChild(routes), FormsModule],
  exports: [LoginFormComponent, RegisterFormComponent, EditProfileFormComponent, EditNanositeFormComponent],
  bootstrap: []
})
export class ComponentModule {}
