import { Component, OnInit, OnDestroy } from '@angular/core';
import { Profile } from '../../app/models/profile/profile.interface';
import { DataService } from '../../app/data.service';
import { AuthService } from '../../app/auth.service';
import { User } from 'firebase/app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-profile-form',
  templateUrl: './edit-profile-form.component.html',
  styleUrls: ['./edit-profile-form.component.scss']
})
export class EditProfileFormComponent implements OnInit, OnDestroy {

  authUser: User;
  authUser$: Subscription;
  profile$: Subscription;
  profile = {} as Profile;
  number: String;
  number$: Subscription;

  constructor(private data: DataService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.authUser$ = this.auth.getAuthUser().subscribe((user: User) => {
      this.authUser = user;
      this.getProfile();
    });
  }
  ngOnDestroy() {
    this.authUser$.unsubscribe();
    this.profile$.unsubscribe();
    this.number$.unsubscribe();
  }



  async saveProfile() {
    if (this.authUser) {
      const result =
        // await this.data.saveProfile(this.authUser, this.profile);
      // await this.data.savePhoneNumber(this.authUser, this.profile.phoneNumber);
      // this function is danger - i should remove option to edit number
      await this.data.saveProfileByNumber(this.profile.phoneNumber, this.profile);
      // console.log(result);
    }

  }

  async getProfile() {
    if (this.authUser) {
      // this.getNumber();
      this.number$ = this.data.getNumber(this.authUser).subscribe(number => {
        if (number.payload.val()) {
          this.number = number.payload.val();

          this.profile$ = this.data.getProfileByPhoneNumber(this.number).subscribe(profile => {
            if (profile.payload.val()) {
              this.profile = profile.payload.val();
            }
          }
          );
        }
      }
      );
    }
    // test!
    // this.data.getAllProfiles();
  }

  // getNumber() {
  //   // tslint:disable-next-line:no-debugger
  //   // debugger;
  //   if (this.authUser) {
  //     this.number$ = this.data.getNumber(this.authUser).subscribe(number => {
  //       console.log(number.payload.val());
  //       console.log(number.payload.val() ? 'yes' : 'no');
  //       if (number.payload.val()) {
  //         this.number = number.payload.val();

  //         this.profile$ = this.data.getProfileByKey(this.number).subscribe(profile => {
  //           console.log(profile.payload.val());
  //           // console.log(profile.payload.val() ? 'yes' : 'no');
  //           if (profile.payload.val()) {
  //             this.profile = profile.payload.val();
  //           }
  //         }
  //         );
  //       }
  //     }
  //     );
  //   }
  // }
  // async getProfileByKey() {
  //   // tslint:disable-next-line:no-debugger
  //   // debugger;
  //   if (this.authUser) {
  //     this.profile$ = this.data.getProfile(this.authUser).subscribe(profile => {
  //       console.log(profile.payload.val());
  //       console.log(profile.payload.val() ? 'yes' : 'no');
  //       if (profile.payload.val()) {
  //         this.profile = profile.payload.val();
  //       }
  //     }
  //     );
  //   }
  // }

}

    // if (this.authUser) {
    //   this.profile$ = this.data.getProfile(this.authUser).subscribe(profile => {
    //     console.log(profile.payload.val());
    //     console.log(profile.payload.val() ? 'yes' : 'no');
    //     if (profile.payload.val()) {
    //    this.profile = profile.payload.val();
    //   }}
    //   );
    // }
