import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from 'angularfire2/database';
import { User } from 'firebase/app';
import { Profile } from './models/profile/profile.interface';
import { Nanosite } from './models/nanosite/nanosite.interface';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


// export interface PhoneChik {
//   phoneNumber: string;
// }

@Injectable({
  providedIn: 'root'
})
export class DataService {
  profileObject: AngularFireObject<Profile>;
  nanositeObject: AngularFireObject<Nanosite>;
  phoneNumberObject: AngularFireObject<String>;
  testProfile: Profile;
  testProfile2: Profile = {} as Profile;


  constructor(private AfDB: AngularFireDatabase) { }

  async savePhoneNumber(uid: string, number: string) {

    this.phoneNumberObject = this.AfDB.object('/usersNumbers/' + uid);
    try {
      this.phoneNumberObject.set(number);
      return true;
    } catch (e) {
      console.error(e);
      console.log(e);
      return false;
    }
  }
  // async saveProfile(user: User, profile: Profile) {

  //   this.profileObject = this.AfDB.object('/profiles/' + user.uid);
  //   try {
  //     this.profileObject.set(profile);
  //     return true;

  //   } catch (e) {
  //     console.error(e);
  //     console.log(e);
  //     return false;
  //   }
  // }

  async saveProfileByNumber(PhoneNumber: string, profile: Profile) {

    this.profileObject = this.AfDB.object('/profiles/' + PhoneNumber);
    try {
      this.profileObject.set(profile);
      return true;

    } catch (e) {
      console.error(e);
      console.log(e);
      return false;
    }
  }
  // getProfile(user: User) {
  //   usersNumbers
  //   this.profileObject = this.AfDB.object('/profiles/' + user.uid);
  //   return this.profileObject.snapshotChanges();
  // }
  // getProfile(user: User) {
  //   this.profileObject = this.AfDB.object('/profiles/' + user.uid);
  //   return this.profileObject.snapshotChanges();
  // }

  getNumber(user: User) {
    this.phoneNumberObject = this.AfDB.object('/usersNumbers/' + user.uid);
    return this.phoneNumberObject.snapshotChanges();
  }

  getProfileByPhoneNumber(phoneNumber: String) {
    console.log(phoneNumber);
    this.profileObject = this.AfDB.object('/profiles/' + phoneNumber);
    return this.profileObject.snapshotChanges();
  }

  getAllProfiles() {
    console.log('all profiles');
    // this.profileObject = this.AfDB.object('/profiles/');
    // console.log(this.profileObject.query.toJSON);
    // return this.profileObject.snapshotChanges();
  }

  // need to check
  getNanositeByPhoneNumber(phoneNumber: String) {
    console.log(phoneNumber);
    this.nanositeObject = this.AfDB.object('/nanosite/' + phoneNumber);
    return this.nanositeObject.snapshotChanges();
  }
  // need to check
  async saveNanosite(phoneNumber: String, object: Nanosite) {
    this.nanositeObject = this.AfDB.object('/nanosite/' + phoneNumber);
    try {
      this.nanositeObject.set(object);
      return true;
    } catch (e) {
      console.error(e);
      console.log(e);
      return false;
    }
  }

  // getNanosite(user: User) {

  //   this.nanositeObject = this.AfDB.object('/nanosite/' + user.uid);
  //   return this.nanositeObject.snapshotChanges();
  // }
  // getKeyByPhone(phoneNumber: string): string {
  //   const ref = this.AfDB.database.ref('profiles');
  //   ref.orderByChild('phoneNumber').equalTo(phoneNumber).on('child_added', function (snapshot) {
  //     console.log(snapshot.key);
  //     return snapshot.key;
  //   });
  //   return 'no match';
  // }

  //       this.AfDB.list('/profiles', ref =>
  // ref.orderByChild('phoneNumber').equalTo(phoneNumber))
  // .valueChanges()
  // .subscribe(numberProfile => {
  //   this.testProfile2 [0] = numberProfile;
  //   console.log(this.testProfile2);
  //   return (this.testProfile2);
  // });
  // return this.testProfile2;

  // const query = this.AfDB.list('/profiles', {
  //   query: {
  //     orderByChild : 'phoneNumber',
  //     equalTo: phoneNumber}});


  // this.nanositeObject =  this.AfDB.object('/nanosite/' + user.uid);
  // return this.nanositeObject.snapshotChanges();
  // }

  // async saveNanosite(user: User, object: Nanosite) {

  //   this.nanositeObject = this.AfDB.object('/nanosite/' + user.uid);
  //   try {
  //     this.nanositeObject.set(object);
  //     return true;
  //   } catch (e) {
  //     console.error(e);
  //     console.log(e);
  //     return false;
  //   }
  // }
}
