import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './login-page/login-page.module#LoginPagePageModule'},
  { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },

  { path: 'login-page', loadChildren: './login-page/login-page.module#LoginPagePageModule' },
  { path: 'dialer-page', loadChildren: './dialer-page/dialer-page.module#DialerPagePageModule' },
  { path: 'register-page', loadChildren: './register-page/register-page.module#RegisterPagePageModule' },
  { path: 'contacts-page', loadChildren: './contacts-page/contacts-page.module#ContactsPagePageModule' },
  { path: 'profile-page', loadChildren: './profile-page/profile-page.module#ProfilePagePageModule' },
  { path: 'inbox-page', loadChildren: './inbox-page/inbox-page.module#InboxPagePageModule' },
  { path: 'channels-page', loadChildren: './channels-page/channels-page.module#ChannelsPagePageModule' },
  { path: 'nanosite-page', loadChildren: './nanosite-page/nanosite-page.module#NanositePagePageModule' },
  // tslint:disable-next-line:max-line-length
  { path: 'profile-nanosite-page/:phone', loadChildren: './profile-nanosite-page/profile-nanosite-page.module#ProfileNanositePagePageModule' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
